import React, {useState} from "react";
import {CognitoUser, AuthenticationDetails} from "amazon-cognito-identity-js";
import UserPool from "../UserPool";
import { useCookies } from 'react-cookie';
// import axios from 'axios';
// import {Redirect} from 'react-router-dom';
// import writeJsonFile from 'write-json-file';

// function signInButton() {
// 	let authenticationData = {
//         Username : document.getElementById("inputUsername").value,
//         Password : document.getElementById("inputPassword").value,
//     };
//
//     let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
//
// 	let poolData = {
//         UserPoolId : _config.cognito.userPoolId, // Your user pool id here
//         ClientId : _config.cognito.clientId, // Your client id here
//     };
//
//     let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
//
//     let userData = {
//         Username : document.getElementById("inputUsername").value,
//         Pool : userPool,
//     };
//
//     let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
//
// 	cognitoUser.authenticateUser(authenticationDetails, {
//         onSuccess: function (result) {
// 			let accessToken = result.getAccessToken().getJwtToken();
// 			console.log(accessToken);
// 			window.open("file:///C:/Users/nbill/Documents/Youtube/CognitoTutorial/profile.html");
//         },
//
//         onFailure: function(err) {
//             alert(err.message || JSON.stringify(err));
//         },
//     });
//   }
//
// function forgotpasswordbutton() {
// 	let poolData = {
//         UserPoolId : _config.cognito.userPoolId, // Your user pool id here
//         ClientId : _config.cognito.clientId, // Your client id here
//     };
//
//     let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
//
//     let userData = {
//         Username : document.getElementById("inputUsername").value,
//         Pool : userPool,
//     };
//
//     let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
//
//     cognitoUser.forgotPassword({
//         onSuccess: function (result) {
//             console.log('call result: ' + result);
//         },
//         onFailure: function(err) {
//             alert(err);
// 			console.log(err);
//         },
//         inputVerificationCode() {
//             let verificationCode = prompt('Please input verification code ' ,'');
//             let newPassword = prompt('Enter new password ' ,'');
//             cognitoUser.confirmPassword(verificationCode, newPassword, this);
//         }
//     });
//   }

export default () => {
    const [cookies, setCookie] = useCookies(['access_token', 'refresh_token', 'user_email', 'accessToken'])
    const [email, setEmail] = useState(cookies.user_email !==undefined ? cookies.user_email : '');
    const [password, setPassword] = useState("");

    const onSubmit = event => {
        event.preventDefault();
        debugger
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });
        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        user.authenticateUser(authDetails, {
            onSuccess: data => {
                debugger
                console.log("onSuccess:", data);
                debugger
                let access_token = data.getAccessToken();
                let id_token = data.getIdToken();
                let refresh_token = data.getRefreshToken();
                // let refresh_token = data.getRefreshToken();
                debugger
                let domain = window.location.hostname.search(".com") > -1 ? '.cavcloud.com' : 'localhost';
                setCookie('access_token', access_token.jwtToken, {'domain': domain, 'path': '/'});
                setCookie('id_token', id_token.jwtToken, {'domain': domain, 'path': '/'});
                setCookie('refresh_token', refresh_token.token, {'domain': domain, 'path': '/'});
                // Note: assign keeps current location in browser history
                window.location.assign('https://dev-authenticated.cavcloud.com');

            }, // userAuthenticate:onSuccess - end

            onFailure: err => {
                debugger
                console.error("onFailure:", err);
                console.log(err.message);
                document.getElementById('error_msg').innerHTML = err.message.fontcolor("red");
                // let email = window.prompt("Forgotten Password: Enter email")

                user.forgotPassword({
                    onSuccess: function (result) {
                        debugger
                        console.log('forgot password call result: ' + result);
                        alert('success')
                    },
                    onFailure: function (err) {
                        debugger
                        alert(err);
                        console.log(err);
                    },
                    inputVerificationCode() {
                        debugger
                        let verificationCode = prompt('Please input verification code ', '');
                        let newPassword = prompt('Enter new password ', '');
                        user.confirmPassword(verificationCode, newPassword, this);
                    }
                })
            },

            newPasswordRequired: data => {
                console.log("newPasswordRequired:", data);
                let pwd_new = window.prompt("Enter New Password");
                user.completeNewPasswordChallenge(pwd_new,{}, {
                    onSuccess: (user) => {
                        debugger
                        console.log('success', user);
                    },
                    onFailure: (error) => {
                        debugger
                        console.log(error);
                    },
                  })
            },

            changePassword: data => {
                console.log("Password Change required:", data);
                let pwd_current = window.prompt("Enter Current Password");
                let pwd_new = window.prompt("Enter New Password");

                user.changePassword(pwd_current, pwd_new, {
                    onSuccess: result => {
                        console.log("Password Changed Successfully", result)
                    },
                    onFailure: err => {
                        console.log("Password Change Failed", err)
                    }
                })
            }
        });
    };

    return (
        <html lang="en">
            <body>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner">
                            <div className="dot1" />
                            <div className="dot2" />
                        </div>
                    </div>
                </div>

                <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light">

                    <div className="container">
                        <a className="navbar-brand logo" href="./index.html">
                            <img src="images/logolight.png" alt="" className="logo-light" height="51" />
                            <img src="images/logo.png" alt="" className="logo-dark" height="51" />
                        </a>
                        <button className="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="mdi mdi-menu" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                                <li className="nav-item active">
                                    <a href="#home" className="nav-link">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#services" className="nav-link">Partners</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#about" className="nav-link">About</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#features" className="nav-link">Features</a>
                                </li>

                            </ul>
                            <div className="call-no">
                                <a href="./index.html" className="nav-link text-white">
                                    <i className="mdi mdi-phone mr-1" />
                                    +44 (01235) 778940
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>

                <section id="home" className="hero-6-bg position-relative vh-100 d-flex align-items-center homed" >
                    <div className="bg-overlay op-75" />
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="hero-6-title-content text-center">
                                    <div className="hero-6-title-icon mb-4">
                                        <i className="mdi mdi-axis-arrow mdi-spin text-white h2" />
                                    </div>
                                    <h1 className="text-white hero-4-title font-weight-light line-height-1_4 mb-4">
                                        Darwin CAV Login Landing Page!
                                    </h1>
                                    <p className="text-white-70">
                                        Project Darwin - Develop the Next Generation of Connected and Autonomous Vehicles (CAVs)
                                    </p>
                                    <button className="btn btn-warning mt-4"
                                            data-toggle="modal"
                                            data-target="#myModal">Login <span className="ml-2 right-icon">&#8594;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section" id="services">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="title text-center mb-5">
                                    <h3 className="font-weight-normal text-dark">Our <span className="text-warning">Partners</span></h3>
                                    <p className="text-muted" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="service-box service-warning">
                                    <div className="mb-5">
                                        <i className="">
                                            <img src="images/partners/partner-tel-white.png"
                                                 alt="Telefonica partner"/>
                                        </i>
                                    </div>
                                    <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">Telefónica</h5>
                                    <p className="text-muted service-subtitle mb-4">elefónica is one of the world’s leading
                                        communications services providers. Its objective is to create, protect and promote fixed
                                        and mobile connections for its customers helping them to take control over their digital
                                        lifestyle. Therefore, its primarily offer to the customers is the connectivity they need
                                        to interact and live in the markets where Telefonica operate through simple products and
                                        services while protecting their data and managing it in a responsible way.</p>
                                    <a href="https://darwincav.com/what-we-do/"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       className="read-more-icon">
                                        <span className="right-icon">&#8594;</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-box service-warning">
                                    <div className="mb-5">
                                        <i className="">
                                            <img src="images/partners/partner-uk-white.png"
                                                 alt={"UK Space Agency partner logo"}/>
                                        </i>
                                    </div>
                                    <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">UK Space Agency</h5>
                                    <p className="text-muted service-subtitle mb-4">The European Space Agency (ESA) is Europe’s
                                        gateway to space. Its mission is to shape the development of Europe’s space capability
                                        and ensure that investment in space continues to deliver benefits to the citizens of
                                        Europe and the world.</p>
                                    <a href="https://darwincav.com/what-we-do/"
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       className="read-more-icon">
                                        <span className="right-icon">&#8594;</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-box service-warning">
                                    <div className="mb-5">
                                        <i className="">
                                            <img src="images/partners/partner-esa-white.png" alt="ESA partner logo"/>
                                        </i>
                                    </div>
                                    <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">eesa</h5>
                                    <p className="text-muted service-subtitle mb-4">We are responsible for all strategic decisions
                                        on the UK civil space programme and provide a clear, single voice for UK space
                                        ambitions. UK Space Agency is an executive agency, sponsored by the Department for
                                        Business, Energy & Industrial Strategy.</p>
                                    <a href="https://darwincav.com/what-we-do/"
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       className="read-more-icon">
                                        <span className="right-icon">&#8594;</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-box service-warning">
                                    <div className="mb-5">
                                        <i className="">
                                            <img src="images/partners/partner-hispasat-white.png"
                                                 alt="Hispasat partner" />
                                        </i>
                                    </div>
                                    <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">hispaat</h5>
                                    <p className="text-muted service-subtitle mb-4">HISPASAT is the Spanish satellite communications
                                        operator, leader in the distribution of content in Spanish and Portuguese. With more
                                        than 25 years of experience, the HISPASAT Group maintains an important presence on the
                                        Iberian Peninsula and in Latin America, where it is now the fourth satellite operator.
                                        HISPASAT has solidly positioned itself in high growth markets and has a stable strategic
                                        client base. HISPASAT distributes more than 1,250 television and radio channels through
                                        its powerful fleet of satellites and is a key driver for the Spanish aerospace industry.
                                        Hispasat Mission is to connect people in any region and be recognized leaders in the
                                        provision of advanced communication services via satellite</p>
                                    <a href="https://darwincav.com/what-we-do/" target="_blank" rel="noopener noreferrer" className="read-more-icon"><span
                                        className="right-icon">&#8594;</span></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-box service-warning">
                                    <div className="mb-5">
                                        <i className="">
                                            <img src="images/partners/partner-glasgow-white.png"
                                                 alt="Glasgow University partner"/>
                                        </i>
                                    </div>
                                    <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">University of Glasgow</h5>
                                    <p className="text-muted service-subtitle mb-4">International business and enterprise: The
                                        International Business and Enterprise (IBE) cluster is a leading centre for the study of
                                        economic globalisation and international business. We conduct world-class research into
                                        the impact of globalisation on organisations and into the role of international firms in
                                        economic development and transnational governance <br/>
                                        Strategy and Technology Management: The Strategy and Technology Management (STM) cluster
                                        has a focus on a range of issues that sit at the interface between the use of technology
                                        within organisations and the implications that those technologies can have for a range
                                        of communities of practice, including social groupings who use or are affected by those
                                        technologies.</p>
                                    <a href="https://darwincav.com/what-we-do/" target="_blank" rel="noopener noreferrer" className="read-more-icon"><span
                                        className="right-icon">&#8594;</span></a>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-box service-warning">
                                    <div className="mb-5">
                                        <i className="">
                                            <img src="images/partners/partner-oxford-white.png"
                                                 alt="Oxford University partner"/>
                                        </i>
                                    </div>
                                    <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">University of Oxford</h5>
                                    <p className="text-muted service-subtitle mb-4">Our community of academics, students, alumni,
                                        staff and friends is truly global and has representatives from all over the world and
                                        from different sectors. We develop people who can analyse complex problems, devise
                                        pragmatic solutions and implement them working as part of a team. This makes our alumni
                                        some of the most effective employees and leaders for the world’s foremost
                                        organisations.</p>
                                    <a href="https://darwincav.com/what-we-do/" target="_blank" rel="noopener noreferrer" className="read-more-icon"><span
                                        className="right-icon">&#8594;</span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="section bg-light" id="about">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="title text-center mb-5">
                                    <h3 className="font-weight-normal text-dark">About <span className="text-warning">Us</span></h3>
                                    <p className="text-muted">We will showcase our findings on a Connected Autonomous Vehicles
                                        (CAV).</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <h2 className="font-weight-light line-height-1_6 text-dark mb-4">
                                    Passionate About providing connected autonomous vehicle services
                                </h2>
                            </div>
                            <div className="col-md-7 offset-md-1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="text-dark font-weight-light f-20 mb-3">Our Mission</h6>
                                        <p className="text-muted font-weight-light">
                                            DARWIN’s aim is to provide a proof of concept of ubiquitous communications
                                            by exploring wider possibilities of connected terrestrial and satellite
                                            networks and satellite GEO and LEO constellations.
                                            We will showcase our findings on a Connected Autonomous Vehicles (CAV).
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section" id="features">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="title text-center mb-5">
                                    <h3 className="font-weight-normal text-dark">Our <span className="text-warning">Features</span></h3>
                                    <p className="text-muted">5G and satellite communication products, allowing our control centers
                                        to track the fleet wherever they are.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-5" id="home2">
                                <div className="features-img">
                                    <img src="images/feature1.jpg" alt="" className="img-fluid d-block mx-auto"/>
                                </div>
                            </div>
                            <div className="col-md-6 offset-md-1">
                                <div className="features-box mt-5 mt-sm-0 mb-4">
                                    <div className="features-icon my-4">
                                        <i className="mdi mdi-laptop-mac" />
                                    </div>
                                    <h5 className="text-dark font-weight-normal mb-3 pt-3">CAVs Activity Reporting</h5>
                                    <p className="text-muted mb-3 f-15">Et harum quidem rerum facilise expedita distinctio nam
                                        libero tempore cum soluta nobis as eligendi optio cumque nihil quis nostrum
                                        exercitationem impedit minus omnis repellendus.</p>
                                    <a href="./index.html" className="f-16 text-warning">Read More <span className="right-icon ml-2">&#8594;</span></a>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mt-5">
                            <div className="col-md-6">
                                <div className="features-box mb-4">
                                    <div className="features-icon my-4">
                                        <i className="mdi mdi-account-group" />
                                    </div>
                                    <h5 className="text-dark font-weight-normal mb-3 pt-3">Real Time Status</h5>
                                    <p className="text-muted mb-3 f-15">Sed perspiciatis unde omnis natus error voluptatem
                                        accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo excepturi
                                        sint occaecati cupiditate architecto.</p>
                                    <a href="#./index.html" className="f-16 text-warning">Read More <span className="right-icon ml-2">&#8594;</span></a>
                                </div>
                            </div>
                            <div className="col-md-5 offset-md-1 mt-5 mt-sm-0" id="home3">
                                <div className="features-img">
                                    <img src="images/feature2.jpg" alt="" className="img-fluid d-block mx-auto"/>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mt-5">
                            <div className="col-md-5" id="home4">
                                <div className="features-img">
                                    <img src="images/feature3.png" alt="" className="img-fluid d-block mx-auto"/>
                                </div>
                            </div>
                            <div className="col-md-6 offset-md-1">
                                <div className="features-box mt-5 mt-sm-0 mb-4">
                                    <div className="features-icon my-4">
                                        <i className="mdi" />
                                    </div>
                                    <h5 className="text-dark font-weight-normal mb-3 pt-3">Digital Live Tracking</h5>
                                    <p className="text-muted mb-3 f-15">At vero eos accusamus iusto odio soluta nobis est eligendi
                                        optio dignissimos ducimus qui blanditiis praesentium as voluptatum deleniti corrupti
                                        quos dolores molestias occaecati.</p>
                                    <a href="./index.html" className="f-16 text-warning">Read More <span className="right-icon ml-2">&#8594;</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="title text-center mb-5">
                                    <h3 className="font-weight-normal text-dark">Get In <span className="text-warning"> Touch</span>
                                    </h3>
                                    <p className="text-muted" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="contact-address">
                                    <h4 className="text-dark mb-4">Contact Info</h4>
                                    <p className="text-muted f-15">(01235) 778940</p>
                                    <p className="text-muted f-15 mb-4" />
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="contact-address">
                                            <h5 className="text-dark mb-3 f-16">Address - A</h5>
                                            <p className="text-muted f-15">Darwin R70,
                                                Harwell Science Campus, Harwell Oxford,
                                                Didcot, OX11 0QX</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contact-address">
                                            <h5 className="text-dark mb-3 f-16">Address - B</h5>
                                            <p className="text-muted f-15">DARWIN London office,
                                                100 New Oxford Street, London</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 offset-lg-1">
                                <div className="custom-form mt-4 mt-lg-0">
                                    <div id="message" />

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group app-label">
                                                <label for="name">Name</label>
                                                <input name="name" id="name" type="text" className="form-control"
                                                       placeholder="Enter your name.."/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group app-label">
                                                <label for="email">Email address</label>
                                                <input name="email" id="email" type="email" className="form-control"
                                                       placeholder="Enter your email.."/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group app-label">
                                                <label for="subject">Subject</label>
                                                <input type="text" className="form-control" id="subject"
                                                       placeholder="Enter Subject.."/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group app-label">
                                                <label for="comments">Message</label>
                                                <textarea name="comments" id="comments" rows="3" className="form-control"
                                                          placeholder="Enter message.." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <button type="submit" id="submit" name="send" className="btn btn-warning">
                                                Send Message
                                                <i className="mdi mdi-telegram ml-2" />
                                            </button>
                                            <div id="simple-msg" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-light">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="subscribe-icon">
                                            <img src="images/icon/1.png" alt="" className="img-fluid mx-auto d-block"/>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="subscribe-icon">
                                            <img src="images/icon/2.png" alt="" className="img-fluid mx-auto d-block"/>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="">
                                            <img src="images/icon/3.png" alt="" className="img-fluid mx-auto d-block"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>

                <section className="footer">
                    <div className="container">

                        <div className="row mt-5">
                            <div className="col-md-12">
                                <div className="text-center text-muted">
                                    <p className="mb-0 f-15">2020 © Darwin CAV.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="modal" id="myModal">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h1 className="modal-title">Login</h1>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>


                            <div className="modal-body">

                                <center>
                                    <h3>Get more things done.</h3>
                                    <p>Access to the Darwin CAV private page</p>

                                </center>


                                <form onSubmit={onSubmit}>
                                    <div className="row">
                                        <div className="form-group col-lg-12">
                                            <span id="error_msg" className="label label-danger" />
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <label>Email:</label>
                                            <input value={email}
                                                   onChange={event => {
                                                       debugger
                                                       setEmail(event.target.value);
                                                       setCookie('user_email', event.target.value)
                                                   }}
                                                   placeholder={"Enter User Name"}
                                                   type="text"
                                                   className="form-control"
                                                   required/>
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <label>Password:</label>
                                            <input value={password}
                                                   onChange={event => setPassword(event.target.value)}
                                                   placeholder="Enter Password"
                                                   type="password"
                                                   className="form-control"
                                                   required/>
                                        </div>

                                        <div className="form-group col-lg-6">
                                            <button type="submit" className="btn btn-primary">Login</button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>
            </body>
        </html>
    );
};
