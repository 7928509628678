import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';

import './App.css';
import Index from './components/home';
import PageNotFound from './components/pageNotFound'

export default () => {
    let base_name = ".";
    return (
        <CookiesProvider>
            <Router basename={ base_name }>
                {/*<Index/>*/}
                <Switch>
                    <Route path='*' component={ Index }/>
                    {/* ERROR PAGES */}
                    <Route path="*" status={404} component={PageNotFound}/>
                </Switch>
            </Router>
        </CookiesProvider>
    );
}