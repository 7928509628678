import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  // New User Pool which would require a forgotten password for user NewJohn
  UserPoolId: 'eu-west-1_1RYH1hN91',
  ClientId: '18tss2hema9p1fet68m2qljudh'

  // Working User Pool but does not work for graphic buckets
  // // ClientId: '34mifatdraad99hlne93b5kfq3'
  // ClientId: '1ji1o5fh67qv2ha62ujt04t0ve'

};

export default new CognitoUserPool(poolData);