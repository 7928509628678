import React from 'react';

class PageNotFound extends React.Component {
    render() {
        return (
            <div class="app-content content">
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-12 col-12 mb-2">

                        </div>
                    </div>
                    <div class="content-body">
                        <section class="card">
                            <div id="invoice-template" class="card-body">
                                <div id="invoice-company-details" class="row">
                                    <div class="col-md-6 col-sm-12 text-center text-md-left">
                                        Error 404
                                        <br />
                                        The requested page could not be found.
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        )
    }
}

export default PageNotFound;
